import {PAYPAL_PAYMENT_SUCCESS} from '../reducers/types/paymentTypes';
import axios from 'axios';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import { trackPromise } from 'react-promise-tracker'

const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api'
const auth = jwt.decode(localStorage.jwtToken)
const apiUrl_backup = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api'


sessionStorage.removeItem('TrustcareGroup' )  
axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
  //  return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
//    toast.error("err 2")
 //   return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      toast.error("Please wait")
      console.log("retry the request", config.url);
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});


export const paymentSuccess = (data ,order_id) => async dispatch =>{

    var mycount 
    var ok=""

    
    try{

        var gateway_fee=sessionStorage.getItem('admin_price')  
        var subtotal_price=sessionStorage.getItem('subtotal_price')  
        var total_price=sessionStorage.getItem('QR_price')  
        var username=sessionStorage.getItem('username')  
        var total_count=sessionStorage.getItem('total_count')  
        
        gateway_fee=sessionStorage.getItem('admin_price')  
        subtotal_price=sessionStorage.getItem('QR_price')  
        total_price=sessionStorage.getItem('QR_price')  
        username=sessionStorage.getItem('username')  
        mycount = 0
    
        var res

        var i;

        var test = 'y';

        try {
        for (i = 0; i < 5; i++) {
            //  toast.success('Server...')
              res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username,  test}))
              
              if(res.data.message === "testing : ") 
                {
                    toast.error('connected')
                    break;
                }    
              
              
              
          }
        } catch (error){}

        for (i = 0; i < 5; i++) {
         
  
        }
        try {
        for (i = 0; i < 5; i++) {
            //  toast.success('Server...')
              res = await trackPromise(axios.post(`${apiUrl}/paypal-success-temp`, { retry: 3, retryDelay: 3000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
              console.log(res.data, 'suc')
              if(res.data.message === "Payment Successful") break;
              if (mycount > 3) 
                  if(res.data.message !== "Payment Successful") break;
              mycount = mycount + 1 
              
          }
        } catch (error){}

        for (i = 0; i < 5; i++) {
          //  toast.success('Server...')
            res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000, data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
            console.log(res.data, 'suc')
            if(res.data.message === "Payment Successful") break;
            if (mycount > 3) 
                if(res.data.message !== "Payment Successful") break;
            mycount = mycount + 1 
            
        }


        
       

    if (String(res.data.count)===String(total_count)) 
       { toast.success("match") }
        else
        {
                 


        for (i = 0; i < 5; i++) {
            toast.success('Update ...')
            res = await trackPromise(axios.post(`${apiUrl}/paypal-success`, { retry: 3, retryDelay: 3000,data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
            if (res.status===200) {break} ;
            console.log(res.data, 'suc')
            if(res.data.message === "Payment Successful") break;
            if (mycount > 3) 
                if(res.data.message !== "Payment Successful") break;
            mycount = mycount + 1 
   
        }
        }
            if(res.data.message == "Payment Successful"){

   //             toast.success('Payment is processing !')
                
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });


            }
            else {
                //toast.error(res.data.message)
                dispatch({
                    type: PAYPAL_PAYMENT_SUCCESS,
                    payload: res.data
                 });
            }
       
        } catch (error){

            

           // //toast.error("Internal Server Error");
           toast.success("OK")
    

           try{

             gateway_fee=sessionStorage.getItem('admin_price')  
             subtotal_price=sessionStorage.getItem('subtotal_price')  
            total_price=sessionStorage.getItem('QR_price')  
            username=sessionStorage.getItem('username')  

            gateway_fee=sessionStorage.getItem('admin_price')  
            subtotal_price=sessionStorage.getItem('QR_price')  
            total_price=sessionStorage.getItem('QR_price')  
            username=sessionStorage.getItem('username')  
            mycount = 0
        

            for (i = 0; i < 5; i++) {
                toast.success('Payment Updating .. Now')
                res = await trackPromise(axios.post(`${apiUrl_backup}/paypal-success`, { retry: 3, retryDelay: 3000,data , auth , order_id, gateway_fee, subtotal_price, total_price, username}))
                console.log(res.data, 'suc')
                if(res.data.message === "Payment Successful") break;
                if (mycount > 3) 
                    if(res.data.message !== "Payment Successful") break;
                mycount = mycount + 1 
                
            }
    
    
    
                if(res.data.message === "Payment Successful"){
    
                    toast.success('Payment is processing !!')
                    dispatch({
                        type: PAYPAL_PAYMENT_SUCCESS,
                        payload: res.data
                     });
                }
                else {
                    //toast.error(res.data.message)
                    dispatch({
                        type: PAYPAL_PAYMENT_SUCCESS,
                        payload: res.data
                     });
                }
           
            } catch (error){
    
                
    
                //toast.error("Internal Server Error");
            }
    
    
    
        }

      
    
   
}