import React from 'react'

import Avatar from "../../../node_modules/@material-ui/core/Avatar";
import Button from "../../../node_modules/@material-ui/core/Button";
import CssBaseline from "../../../node_modules/@material-ui/core/CssBaseline";
import Typography from "../../../node_modules/@material-ui/core/Typography";
import { makeStyles } from "../../../node_modules/@material-ui/core/styles";
import Container from "../../../node_modules/@material-ui/core/Container";




export default function Maintenance() {
 
 const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));


const classes = useStyles();




  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>

        <p></p>
        <p></p>

        <Avatar className={classes.avatar}></Avatar>
        <Typography component="h1" variant="h5">
          Maintenance Notice
        </Typography>
        

        <p></p>
    
        
        <Typography component="h1" variant="h6">
        <div>
        The website is under maintenance from 11.30pm to 12.30am.
        </div>
        </Typography>
      

        <Button
          type="text"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          
        >
          Thank you for your patience.
        </Button>
        
      </div>
    </Container>
  );
}
