import {FETCH_TICKET, FETCH_TODO} from '../reducers/types/ticketTypes';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt from 'jsonwebtoken'
import setAuthorizationToken from './authActions'
import { trackPromise } from 'react-promise-tracker'
import {logout} from '../actions/authActions';


const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api'


axios.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message.includes("timeout") || message.includes("Network Error"))) {
  //    toast.error("err 2")
   //   return Promise.reject(err);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise((resolve) => {
      setTimeout(() => {
        toast.error("Please wait")
        console.log("retry the request", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios(config));
  });
  
const config = setAuthorizationToken(localStorage.jwtToken)
const auth = jwt.decode(localStorage.jwtToken)
export const getAllTicket = () => async dispatch =>{

    const {username} = auth

    
 
   // toast.success("Retrieving Data");
    
      
    sessionStorage.setItem('otpcode', "none")
    sessionStorage.setItem('otpno', "none")

    try{
        

  //  const res1 =    await trackPromise(axios.post(`${apiUrl}/auth/getshopname`, {username}, config))

    const res1 = await trackPromise(axios.get(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/auth/getshopname2?username=`+username, config))
    
    if(res1.data){
  
  var shop = res1.data.data

 // toast.success("Get Shopname : "+ shop);

 sessionStorage.setItem('shop', shop)  
 localStorage.setItem('shop', shop)  

 var shopname = sessionStorage.getItem('shop')  
 var isactive

 
 if (shopname === 'Sheng Cheong'  )
 {
 shop  = 'Sheng Cheong Pawnshop'    
 sessionStorage.setItem('shop', shop)  
 localStorage.setItem('shop', shop)  


 }
    




    }
    else{ 
    }

} catch (error){

 ///   sessionStorage.setItem('Logout', 'done');
//    localStorage.removeItem('jwtToken')
 //   dispatch(logout())
 }




//---------------------
//toast.success(shop);
var shopname = sessionStorage.getItem('shop')  
var isactive


if (shopname === 'Sheng Cheong'  )
{
shop  = 'Sheng Cheong Pawnshop'    
sessionStorage.setItem('shop', shop)  
localStorage.setItem('shop', shop)  


}
   




    try{
        

    

        try{
    //        const res = await trackPromise(axios.post(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/ticket`,{username}, config))
    
            var res = await trackPromise(axios.get(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/dra/ticket?username=`+username, config))
    
            sessionStorage.setItem('username', username );
          
            if(res.data.invalid_token  ){
                console.log(res.data)
                localStorage.removeItem('jwtToken')
                window.location.replace("/login");
    
            }
            else if(res.data.data == "No Data Found"){
                toast.error(res.data.data);
                return false
    
            }
           
            dispatch({
                type: FETCH_TICKET,
                payload: res.data
             });
            } catch (error){
    
                
             res = await trackPromise(axios.get(`https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api/dra/ticket?username=`+username, config))
    
            sessionStorage.setItem('username', username );
          
            if(res.data.invalid_token  ){
                console.log(res.data)
                localStorage.removeItem('jwtToken')
                window.location.replace("/login");
    
            }
            else if(res.data.data == "No Data Found"){
                toast.error(res.data.data);
                return false
    
            }
           
            dispatch({
                type: FETCH_TICKET,
                payload: res.data
             });
            
            

            }
    


        } catch (error){

           sessionStorage.setItem('Logout', 'done');
           localStorage.removeItem('jwtToken')
           dispatch(logout())
        }
     

 

    
    
    
}

export const getAllTodoList = () => async dispatch =>{

    const {username} = auth

    toast.success("Retrieving Data");
        

    try{
        const res = await trackPromise(axios.post(`${apiUrl}/ticket`,{username}, config))

        if(res.data.invalid_token  ){
            console.log(res.data)
            window.location.replace("/login");

        }
        else if(res.data.data == "No Data Found"){
            toast.error(res.data.data);
            return false

        }
       
        dispatch({
            type: FETCH_TODO,
            payload: res.data
         });
        } catch (error){

            return "Internal server error"
        }


}