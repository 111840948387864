import { useRef,useState, useEffect } from "react";
import React from 'react'

import Avatar from "../../../node_modules/@material-ui/core/Avatar";
import Button from "../../../node_modules/@material-ui/core/Button";
import CssBaseline from "../../../node_modules/@material-ui/core/CssBaseline";
import TextField from "../../../node_modules/@material-ui/core/TextField";
import Typography from "../../../node_modules/@material-ui/core/Typography";
import { makeStyles } from "../../../node_modules/@material-ui/core/styles";
import Container from "../../../node_modules/@material-ui/core/Container";
import "react-toastify/dist/ReactToastify.css";
import { register } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import history from '../../history';
import {  toast } from 'react-toastify';
import axios from 'axios';
import {logout} from '../../actions/authActions';
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import {  useLocation  } from "react-router-dom";




// ==========================================================================================================
// URL and authorization header
// ==========================================================================================================
const apiUrl = 'https://pdmsonlinebanfooksoutheast.azurewebsites.net/online-pdms-trustcare/public/api'
//const apiUrl = 'https://pdmstriggereast.azurewebsites.net/online-pdms/public/api'

function setAuthorizationToken (token){
    if(token){
        return {headers:{
            'Authorization': `Bearer ${token}`
        }}
    }
    else {
        return false    
    }
}
const config = setAuthorizationToken("MiA4MzA0In0.Lbs5QaXwinnwsKwpfy3APkO3poUjBljVOOcIk6-XUIQTEST")
// ==========================================================================================================

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Trigger() {
  const [form, setForm] = useState({
    username: "",
    alias_username: "",
    sms_handphone: "",
    password: "",
    password_confirmation: "",
    email: ""
  });
  const dispatch = useDispatch()
const authData = useSelector(state => state.auth.register)
const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

const [isAuthenticate, setIsAuthenticate] = useState(false);
 const windowUrl = window.location.search;
 
 var pureText = windowUrl;
 var myres ="";

  let query = useQuery();

  pureText=query.get("name")
  
  const [fruit, setFruit] = useState('Nil');
  const [fruiturl, setFruiturl] = useState('Nil');
  const [posts, setPosts]=useState([])
  const getPosts = async () => {
  try {
  const userPosts = await axios.get("https://jsonplaceholder.typicode.com/posts")
    
    setPosts(userPosts.data);  // set State
  
  } catch (err) {
    console.error(err.message);
  }
  };

  const [state, setState] = useState({ data: null, error: false, loading: true })
  const url = 'https:///pdmsqrcode2.azurewebsites.net/expire'
  useEffect(() => {
    let isMounted = true
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
    // setState(state => ({ data: state.data, error: false, loading: true }))
     callapi()
    
    }, 90000)
 // 9 sec call one time

    return () => {
        clearInterval(intervalId); //This is important
        isMounted = false // Let's us know the component is no longer mounted.
    }
 
 }, [url])

  
  useEffect(()=>{
    
    getPosts()
  },[])  // includes empty dependency array 




  function callapi()
  {

    form.username = pureText;

    
    if (form.username == "")
    {
      alert('No Username');
    }
  else
  {
   
   // toast.error("Please wait. Sending Request");
    
     
  //  var username = pureText;
    var password = "111";
    var username = "me42";

    console.log(username);
    axios.get("https:///pdmsqrcode2.azurewebsites.net/expire")
  //  const res = await trackPromise(axios.post(`${apiUrl}/auth/login`, {username, password}, config))

    .then(response => {
      console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
      console.log(response.data);
      console.log(response.data.qr_issued);
     console.log(response.data.message);

      myres = response.message;
      toast.error(myres);

      //    this.setState({Tododata: response.data} )
   //  alert(response.message)  // onlinePDMS API test
      //alert(response.data)
  //    dispatch(logout());
 

    })
    .catch(error => {
   //   toast.error(error);
   
      console.log('"API CALL fail');
      console.log(error);
  //    alert('Your Request is unsuccessful.')  // onlinePDMS API test
   //   alert(error)  // onlinePDMS API test

      
   //   dispatch(logout());
    });

  
  }

  }

var myref = "";

function callapionce()
  {

    form.username = pureText;

    
    if (form.username == "")
    {
      alert('No Username');
    }
  else
  {
   
   // toast.error("Please wait. Sending Request");
    
     
  //  var username = pureText;
    var password = "111";
    var username = "me42";

    console.log(username);
    axios.get("https://dranixmyapp1.herokuapp.com/qr-request")
  //  const res = await trackPromise(axios.post(`${apiUrl}/auth/login`, {username, password}, config))

    .then(response => {
      console.log('-------------------------------------------------------------------DRANIX API CALL ONCE');
      console.log(response.data);
      console.log(response.data.ref);
      myref = response.data.ref
    // console.log(response.data.message);
    setFruit(myref);
    aurl = "https://dranixmyapp1.herokuapp.com/qrcode?total=1&ref="+myref+"&shopcode=_DRA"
    setFruiturl(aurl);
      myres = response.message;
      toast.error(myres);

      //    this.setState({Tododata: response.data} )
   //  alert(response.message)  // onlinePDMS API test
      //alert(response.data)
  //    dispatch(logout());
 

    })
    .catch(error => {
   //   toast.error(error);
   
      console.log('"API CALL fail');
      console.log(error);
  //    alert('Your Request is unsuccessful.')  // onlinePDMS API test
   //   alert(error)  // onlinePDMS API test

      
   //   dispatch(logout());
    });

  
  }

  }

  var aurl = "https://dranixmyapp1.herokuapp.com/qrcode?total=1&ref="+fruit+"&shopcode=_DRA"
  var urls = [
    "https://dranixmyapp1.herokuapp.com/qrcode?total=1&ref="+fruit+"&shopcode=_DRA"
    
  ];

    

        function Test() {
          const [loading, setLoading] = useState(true);
          const counter = useRef(0);
          const imageLoaded = () => {
            counter.current += 1;
            if (counter.current >= urls.length) {
              setLoading(false);
            }
          }
          return <React.Fragment>
            <div style={{display: loading ? "block" : "none"}}>
               Loading images,
            </div>
            <div style={{display: loading ? "none" : "block"}}>
              {urls.map(url => 
                <img key={url} src={url} onLoad={imageLoaded} width="200" height="200"/>)}

            </div>
          </React.Fragment>;
        }

        

        
useEffect(() => {

  
  callapionce()
  //getPosts();
  

   if(authData.message == "Credentials Correct"){
    
    var now = new Date();
    var minutes = 5;
    now.setTime(now.getTime() + (minutes * 60 * 1000));
    document.cookie = `otp_number=${authData.number}; expires= ${now.toUTCString()}`


    setIsAuthenticate(true);
  }
  
  }, [authData]);

  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(4),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }));

  function validate() {
  
    form.password = form.password_confirmation
    
   // if (
   //   form.password == "" ||
   //   form.password_confirmation == "" 
  //    
  //  ) {
  //    toast.error("Please input all fields");
  //    return false;
  //  } else if (form.password != form.password_confirmation) {
   //   toast.error("Confirm Password Does Not Match!");
    //  return false;
  //  }

    return true
  }


  function handleChange(event) {
    const value = event.target.value;
    setForm({ ...form, [event.target.name]: value });
  }

  function submitForm() {
    const isValidate = validate();

    if (isValidate == true) {
      dispatch(register(form))
    }
  }



  function savepassword() {
    const isValidate = validate();

    if (isValidate == true) {
      callapi();
    }
  }


  const classes = useStyles();

  if(isAuthenticate){
    return <Redirect to='/otp' />;

  }
  if(isAuthenticated){
    history.push('/')
  }

  
// http://localhost:3000/resetpassword?me42
console.log("----------------------------------------------------------------------start");


  //const parsed = queryString.parse(windowUrl);
  console.log(windowUrl);
 
console.log(pureText);

//pureText = "me42";

console.log("----------------------------------------------------------------------end");


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
  
        <Typography component="h1" variant="h5">
          {myres}
        </Typography>
        
        <div>

<Test/>

      </div>
  
        <Typography component="h1" variant="h6">
     {/*  User: {pureText}     <User name={query.get("name")} /> */}
        </Typography>
      
  {/*
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />

       
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label="Reference"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={handleChange}
        />
<div> Code : {fruiturl}</div>

*/}
 <div>
      <div> Ref : {fruit}</div>

      
    </div>


        <Button
          type="text"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={savepassword}
        >
          https://dranixmyapp1.herokuapp.com/qr
        </Button>
        {/*
        or https://pdmsqrcode1.azurewebsites.net/
*/}

        <Grid item xs = {4}>
                <Link href={fruiturl} variant="body2">
                  {"Download QR Image"}
                </Link>
              </Grid>
      </div>
    </Container>
  );
}
