import React from 'react';
import {paymentSuccess} from '../../actions/paymentActions';
import { useDispatch } from 'react-redux'


export default function TotalModal(props) {
  
  
  var subtotal_price = 0;
  
  for (let i = 0; i < props.data.length; i++) {
    let data = props.data[i];
    subtotal_price = subtotal_price + data.interest_payable ;
  }
  sessionStorage.setItem('QR_price_int', subtotal_price );
  
  var subtotal_price = 0;
  
  for (let i = 0; i < props.data.length; i++) {
    let data = props.data[i];
    subtotal_price = subtotal_price + data.interest_payable + 2.0;
  }
  
  subtotal_price = subtotal_price + 0.00;


  var admin_price = 0;
  
  for (let i = 0; i < props.data.length; i++) {
    let data = props.data[i];
    admin_price = admin_price + 2.0;
  }
    




  sessionStorage.setItem('QR_price', subtotal_price );
  sessionStorage.setItem('admin_price', admin_price );
  //  subtotal_price='3.33'
 var k = props.data.length;
 
 sessionStorage.setItem('total_count', k );
 
 subtotal_price=sessionStorage.getItem('QR_price_int')

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (<div>
    
    
    Int : {formatter.format(subtotal_price)}

    
  </div>);
}
